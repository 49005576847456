@import "../../const/settings.scss";

.wpFormChatboxMobile {
  border-radius: 10px;
  border: none;
  .chatHeader {
    border-radius: 10px 10px 0 0;
    border: 1px solid rgba(41, 53, 104, 0.1);
    background: #fff;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    padding: 10px;
    height: auto;
    z-index: 2;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      color: #293568;
      font-size: 17px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
    }
  }
  .chatBody {
    background: #f5f5f5;
    max-height: 500px; /* Giới hạn chiều cao tối đa */
    overflow-y: auto;
    // overflow-y: scroll; /* Cho phép cuộn dọc */
    background: #f5f5f5;
    padding: 12px 19px;
    scrollbar-color: #d9d9d9 #f5f5f5 !important;
    .chatContent {
      margin-top: 20px;
      border-radius: 10px;
      background: var(--Color-3, #fff);
      padding: 10px 8px;
      font-size: 16px;
      &:first-child {
        margin-top: 0px;
      }
      .chatBoxInput {
        border-radius: 10px;
        border: 1px solid rgba(41, 53, 104, 0.1);
        // background: #f5f5f5;
      }
      textarea {
        border-radius: 10px;
        border: 1px solid rgba(41, 53, 104, 0.1);
        // background: #f5f5f5;
      }
      .ant-form-item-label > label,
      span {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px;
      }
      .ant-form-item:last-child {
        margin-bottom: 0px !important;
      }
      b {
        font-size: 16px;
      }
      .ant-form-item {
        margin-bottom: 12px;
      }
      @include media(sm) {
        span {
          font-size: 14px;
        }
      }
    }
    .relatedClinic {
      margin-top: 10px;
      .clinicItem {
        border-radius: 5px;
        background: var(--Color-3, #fff);
        border: 1px solid rgba(41, 53, 104, 0.1);
        padding: 15px;
        display: flex;
        flex-direction: column;
        .clinicContainer {
          display: flex;
          .wpLeft {
            text-align: center;
            margin-right: 8px;
            .logo {
              width: 40px !important;
              position: static !important;
              height: 40px !important;
              border-radius: 50%;
              object-fit: contain;
              background: #fff;
            }
            .distance {
              color: rgba(0, 0, 0, 0.5);
              font-size: 10px;
              margin-top: 6px;
            }
          }
          .wpRight {
            width: 100%;
            .titleClinic {
              display: flex !important;
              justify-content: space-between;
              color: var(--color-bg-navy, #293568);
              font-size: 12px;
              font-style: normal;
              font-weight: 600;
              line-height: 20px;
              text-transform: capitalize;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              overflow: hidden;
              margin-bottom: 0px;
            }
            .firstLineTag {
              display: flex;
              margin: 6px 0;
              justify-content: space-between;
              .wpTagInfo {
                display: flex;
                color: var(--Color-5, #293568);
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                border-radius: 8px;
                align-items: center;
                text-transform: capitalize;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
                .iconLocation {
                  width: 12px;
                  height: 12px;
                  margin-right: 8px;
                }
              }
            }
            .wpAddress {
              display: flex;
              color: var(--Color-Bg-Navy, #2a3467);
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%;
              align-items: center;
              .iconLocation {
                width: 20px;
                height: 20px;
                margin-right: 8px;
              }
            }
            .wpRatingLine {
              display: flex;
              align-items: center;
              .ratingPoint {
                display: flex;
                align-items: center;
                .point {
                  color: #ffa800;
                  font-size: 10px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 150%;
                  padding: 0px 4px;
                  border-radius: 4px;
                  border: 2px solid #ffa800;
                  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
                }
                .iconRatingStar {
                  margin-left: 4px;
                }
              }
              .dash {
                width: 1px;
                height: 13px;
                background: rgba(0, 0, 0, 0.2);
                margin: 0 6px;
              }
              .ratingRank {
                color: #ffa800;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 150%;
                text-transform: capitalize;
              }
              .ratingQuantity,
              .numberOfConsultation {
                color: var(--Color-Text, #293568);
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
              }
            }
          }
        }
        .btn-receive-advice {
          margin: 10px auto 0px;
          width: fit-content;
          background: #00c5d7;
          border: none;
          border-radius: 5px;
          height: 23px;
          line-height: 0;
          > span {
            color: white;
            font-size: 12px;
            display: flex;
          }
        }
        .btn-receive-advice:disabled {
          > span {
            color: rgba(0, 0, 0, 0.25) !important;
          }
          border-color: #d9d9d9 !important;
          background: #f5f5f5 !important;
          text-shadow: none !important;
          box-shadow: none !important;
          background-color: #d3d3d3 !important;
          cursor: not-allowed !important;
        }
      }
    }
  }
  .chatFooter {
    padding: 0 13px 13px;
    background: #f5f5f5;
    border-radius: 10px;
    border: none;
    .btnChatBox {
      border-radius: 5px !important;
      color: var(--Color-3, #fff) !important;
      background: var(--Color-6, #29c0d3) !important;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      height: 42px;
      border: none !important;
      width: 100%;
      span {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
      &:hover {
        color: #fff;
        border-color: #fff;
        background: #29c0d3 !important;
      }
      @include media(sm) {
        height: 30px;
      }
    }
    .disabledBtn {
      background-color: #d6d6d6 !important;
      color: #a0a0a0 !important;
    }
  }
}

.modal-chatbot-mobile {
  .ant-modal-close {
    top: 4px !important;
  }
  .ant-modal-content {
    .ant-modal-close-x {
      font-size: 20px;
    }
  }
}
